import ApiClient from '@/api/apiClient';

class CheckoutService {
    async checkout(instanceId: string, returnUrl: string) {
        const url = `/checkout`;
        const defaultValue = null;
        const errorPath = '[api:checkout:checkout]';
        return await ApiClient.account.post({ url, defaultValue, errorPath, data: { instanceId, returnUrl } });
    }
    async checkSuccess(sessionId: string) {
        const url = `/checkout/${sessionId}`;
        const defaultValue = null;
        const errorPath = '[api:checkout:checkSuccess]';
        return await ApiClient.account.get({ url, defaultValue, errorPath });
    }
}

export default new CheckoutService();
